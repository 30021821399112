export const slugify = (value: string | undefined | null, delimiter = '_') => {
  if (!value) {
    return '';
  }

  const specialCharacters =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/,:;';
  const acceptedChars =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz_------';
  const p = new RegExp(specialCharacters.split('').join('|'), 'g');

  return value
    .trim()
    .toLowerCase()
    .replace(/\s+/g, delimiter) // Replace spaces with -
    .replace(p, (c) => acceptedChars.charAt(specialCharacters.indexOf(c))) // Replace special chars
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove non word chars
    .replace(/--+/g, delimiter); // Replace multiple dashes with single
};

export const cleanTextFromUrl = (str: string) => {
  if (str) {
    return str.replace(/[+]/g, ' ').replace(/%20/g, ' ');
  }
  return '';
};

export const wordifySlug = (slug: string) => {
  if (!slug) {
    return '';
  }
  return slug
    .replace(/-/g, ' ')
    .split(' ')
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ');
};
